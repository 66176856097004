
<script>

export default {

  created() {
    window.location.href = "https://karukaj.net";
  },

};


</script>





<template>
 
        
      <div class="container"></div>



</template>
